import React from 'react';
import './careersmodal.css'

const CareersModal = ({ isOpen, onClose }) => {
  if (!isOpen) {
    return null;
  }

  return (
    <div className="modal">
      <div className="modal-content">
        <h2>Careers</h2>
        <p>
        Interested candidates are encouraged to apply by emailing us at info@vitalityassistant.com
        </p>
       
        <button className='modal-close-btn' onClick={onClose}>&times;</button>
      </div>
    </div>
  );
};

export default CareersModal;