import './desktop.css';
import Header from './components/header';
import Footervital from './components/footervital';
import Content from './components/content';
import Content1 from './components/content1';

import Vitalityheader from './components/vitalityheader';

import Outro from './components/outro';



function DesktopApp() {


  return (
    <div className='desktopapp'>
      <Vitalityheader />
      <Header /> 
      <Content />
      <Content1 />
      <Outro/>
      <Footervital />
    </div>
  );
  }

export default DesktopApp;