import React from 'react';

const PrivacyPolicyModal = ({ isOpen, onClose }) => {
  if (!isOpen) {
    return null;
  }

  return (
    <div className="modal">
      <div className="modal-content">
        <h2>Privacy Policy</h2>
        <p>
        Last updated: 5/13/2023
        </p>
        <p>
        Outlive Technologies ("we", "us", or "our") operates the Vitality Assistant application (the "Service"). Your privacy is important to us, and this Privacy Policy explains how we collect, use, disclose, and protect your personal information when you use our Service.
        </p>
        <p>
        By using the Service, you agree to the collection and use of information in accordance with this Privacy Policy.
        </p>
        <p><strong>
          1. Information Collection and Use
          </strong></p>
        <p>
        When you use our Service, we may collect certain information to provide and improve the Service, including:
        </p>
        <p>
        a. Personal Information: We may collect personal information such as your name, email address, and other identifiable information to create an account, provide customer support, or send you newsletters and promotional materials.
        </p>
        <p>
        b. Usage Data: We may collect data on how you access and use the Service, such as your device type, IP address, browser type, and operating system.
        </p>
        <p>
        c. Health and Lifestyle Data: We collect data that you provide to us through the chatbot, such as your age, weight, activity levels, and dietary habits, to provide personalized health and lifestyle advice.
        </p>
        <p><strong>2. Data Retention</strong></p>
<p>We retain your personal information for as long as necessary to provide the Service or until you request that we delete your information. We may retain aggregated, anonymized, or de-identified data for analytical purposes.</p>

<p><strong>3. Data Security</strong></p>
<p>We implement appropriate security measures to protect your personal information from unauthorized access, use, or disclosure. However, please note that no method of electronic storage or transmission is 100% secure, and we cannot guarantee absolute security.</p>

<p><strong>4. Sharing of Information</strong></p>
<p>We do not sell or rent your personal information to third parties. We may share your information with third-party service providers that perform services on our behalf, such as data storage, analytics, and customer support, provided that these service providers are contractually obligated to protect your information.</p>

<p><strong>5. Legal Requirements</strong></p>
<p>We may disclose your personal information if required by law or in response to a subpoena, court order, or a request from a law enforcement agency.</p>

<p><strong>6. Changes to This Privacy Policy</strong></p>
<p>We reserve the right to update or modify this Privacy Policy at any time. We will notify you of any changes by posting the new Privacy Policy on this page. You are advised to review this Privacy Policy periodically for any changes.</p>

<p><strong>7. Contact Us</strong></p>
<p>If you have any questions or concerns about this Privacy Policy or our privacy practices, please contact us at info@vitalityassistant.com.</p>

        <button className='modal-close-btn' onClick={onClose}>&times;</button>
      </div>
    </div>
  );
}
export default PrivacyPolicyModal;