import React from 'react';
import './mobileheader.css';
import vitality1 from '../../images/vitality1.png';

const Mobileheader = () => {
 
  return (
    <div className="mobilevitalityheader">
      <img className='mobilevitalityimgvitalityassistant' src={vitality1} alt='vitality assistant' />
      
    </div>
  );
};

export default Mobileheader;
