import React from 'react';
import './mobilecontent.css';
import market from '../../images/market.webp'

const MobileContent = () => {
  return (
    <div className='mcontainerforcontent'>
    <div className='mbanner'>
    <img src={market} alt='Logo' className='mfullmockupforcontent'/>
    <div className='msloganforcontent'>
      <p>Vitality's mission is to create an online platform where users can determine their needs, educate themselves, and buy the highest quality vitamins, supplements, and nutrition-related products</p> 
      <p classname='mcontentdescription'>Users can count on Vitality Assistant, an AI assistant that will help users determine what dietary or lifestyle changes they need and keep them on track once that plan is implemented</p>
    </div>
    
    </div>
    </div>
  );
};

export default MobileContent;