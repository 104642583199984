import React, { useState } from 'react';
import './footervital.css';
import VA from '../images/VA.png';
import PrivacyPolicyModal from './privacypolicymodal';
import TermsOfUseModal from './termsofusemodal';
import FeedbackModal from './feedbackmodal';
import CareersModal from './careersmodal';


const Footervital = () => {
  const [showPrivacyPolicyModal, setShowPrivacyPolicyModal] = useState(false);
  const [showTermsOfUseModal, setShowTermsOfUseModal] = useState(false);
  const [showFeedbackModal, setShowFeedbackModal] = useState(false);
  const [showCareersModal, setShowCareersModal] = useState(false);
  
  

  

  

  return (
    <footer className="footervital">
      <div className="footer-content">
        <nav className="footer-links">
          <button
            className="footer-link-button"
            onClick={() => setShowPrivacyPolicyModal(true)}
          >
            Privacy Policy
          </button>{' '}
          |{' '}
          <button
            className="footer-link-button"
            onClick={() => setShowTermsOfUseModal(true)}
          >
            Terms Of Use
          </button>{' '}
          |{' '}
          <button
            className="footer-link-button"
            onClick={() => setShowFeedbackModal(true)}
          >
            Inquires & Feedback
          </button>
        </nav>
        <nav>
        <button className="footer-link-button">
            Vitality Assistant
          </button>{' '}
          |{' '}
        <button className='footer-link-button'>Vitality Market
        </button>{' '}
          |{' '}
          <button 
          className='footer-link-button'
          onClick={() => setShowCareersModal(true)}
          >
            Careers
          </button>
          </nav>
        
      </div>
      <hr className="footerlines" />
      <div className="secondfootercontainer">
        <div className="copyright">
          Copyright © 2023 Outlive Technologies, LLC. All Rights Reserved
          <img className="imgVAfooter" src={VA} alt="VA" />
        </div>
      </div>
      <PrivacyPolicyModal
        isOpen={showPrivacyPolicyModal}
        onClose={() => setShowPrivacyPolicyModal(false)}
      />
      <TermsOfUseModal
        isOpen={showTermsOfUseModal}
        onClose={() => setShowTermsOfUseModal(false)}
      />
      <FeedbackModal
        isOpen={showFeedbackModal}
        onClose={() => setShowFeedbackModal(false)}
      />
      <CareersModal
        isOpen={showCareersModal}
        onClose={() => setShowCareersModal(false)}
      />
    </footer>
  );
};

export default Footervital;