import React from 'react';
import './outro.css';


const Outro = () => {
    const handleButtonClick = () => {
        window.location.href = 'https://docs.google.com/forms/d/e/1FAIpQLSejEwpToNF6aoywrrcA3QNPxEG4RK3WFhCA9mg2boh3zBTOvQ/viewform?usp=sf_link';
      };

      const handleEmailFormClick = () => {
        window.location.href = 'https://docs.google.com/forms/d/e/1FAIpQLSdgE-WRNyHlhn4eJb_XaV1vWfIiA0zhKzYZYzyHUChSGeWBWg/viewform?usp=sf_link';
    };

  return (
    <div className='containerforoutro'>
    <button onClick={handleEmailFormClick} className='vitalitybuttonoutro'>email form</button>
    <button onClick={handleButtonClick} className='vitalitybuttonoutro'>questionnaire</button>
    
    
    </div>
  );
};

export default Outro;