import React from 'react';
import './feedbackmodal.css'

const FeedbackModal = ({ isOpen, onClose }) => {
  if (!isOpen) {
    return null;
  }

  return (
    <div className="modal">
      <div className="modal-content">
        <h2>Inquires & Feedback</h2>
        <p>
        We want to make Vitality Assistant & Market the best place for people to change their nutrition for the better, and we need your help to do it! If you have any feedback or suggestions for improvement, please don't hesitate to reach out to us at info@vitalityassistant.com. If you are interested in Vitality, please spread the word to your friends and family! You can help us make this product a reality!
        </p>
       
        <button className='modal-close-btn' onClick={onClose}>&times;</button>
      </div>
    </div>
  );
};

export default FeedbackModal;