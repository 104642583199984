import React from 'react';
import MobileApp from './mobile';
import DesktopApp from './desktop';
import { useMediaQuery } from 'react-responsive';


const App = () => {
  const isMobile = useMediaQuery({ query: '(max-width: 767px)' });

  return (
    <div className="App">
      {isMobile ? <MobileApp /> : <DesktopApp />}
    </div>
  );
};

export default App;