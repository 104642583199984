import React from 'react';
import './secondheader.css';


const SecondHeader = () => {


  return (
    <div className="secondheader">
     
      <div className='secondcompanyname'>Changing the stigma of vitamins, supplements, and nutrition through accessibility and education</div>
      
    </div>
  );
};

export default SecondHeader;