import React from 'react';

const TermsOfUseModal = ({ isOpen, onClose }) => {
  if (!isOpen) {
    return null;
  }

  return (
    <div className="modal">
      <div className="modal-content">
        <h2>Terms Of Use</h2>
        
<p>Last updated: 5/13/2023</p>
<p>Welcome to Vitality Assistant, an AI-powered health and lifestyle chatbot developed by Outlive Technologies ("Outlive," "we," "us," or "our"). By using Vitality Assistant and its associated services (collectively, the "Services"), you agree to be bound by these Terms of Use ("Terms"). If you do not agree to these Terms, you may not access or use the Services.</p>
<p><strong>1. Eligibility</strong></p>
<p>You must be at least 13 years old to use the Services. By accessing or using the Services, you represent and warrant that you are at least 13 years old and have the legal capacity to enter into these Terms.</p>
<p><strong>2. License</strong></p>
<p>Subject to your compliance with these Terms, Outlive grants you a limited, non-exclusive, non-transferable, and revocable license to access and use the Services for your personal, non-commercial purposes.</p>
<p><strong>3. Restrictions</strong></p>
<p>You agree not to:</p>
<p>a. Use the Services for any illegal or unauthorized purposes, or in a way that violates any applicable laws, rules, or regulations;</p>
<p>b. Modify, copy, reproduce, reverse engineer, or attempt to derive the source code of the Services;</p>
<p>c. Distribute, sublicense, rent, lease, sell, or transfer your rights to the Services;</p>
<p>d. Use the Services to send unsolicited communications, spam, or any other content that may be harmful, offensive, or infringing on the rights of others;</p>
<p>e. Interfere with the security, operation, or performance of the Services, including by attempting to gain unauthorized access, introducing viruses or other malicious code, or engaging in denial-of-service attacks.</p>
<p><strong>4. User-Generated Content</strong></p>
<p>You are solely responsible for any content you create, transmit, or display while using the Services ("User Content"). By submitting User Content, you grant Outlive a worldwide, non-exclusive, royalty-free, sublicensable, and transferable license to use, reproduce, distribute, modify, and display your User Content in connection with the Services.</p>
<p><strong>5. Disclaimer</strong></p>
<p>The Services are provided for informational purposes only and should not be used as a substitute for professional medical advice, diagnosis, or treatment. Always consult a qualified healthcare professional before making decisions about your health or lifestyle.</p>
<p><strong>6. Limitation of Liability</strong></p>
<p>To the maximum extent permitted by law, Outlive and its affiliates, directors, employees, and agents shall not be liable for any indirect, incidental, special, consequential, or punitive damages, or any loss of profits or revenues, whether incurred directly or indirectly, or any loss of data, use, goodwill, or other intangible losses, resulting from your access to or use of, or inability to access or use, the Services.</p>
<p><strong>7. Changes to the Terms</strong></p>
<p>Outlive reserves the right to modify these Terms at any time. If we make material changes, we will notify you by updating the "Last updated" date at the top of this document. Your continued use of the Services after any changes become effective constitutes your acceptance of the new Terms.</p>
<p><strong>8. Termination</strong></p>
<p>Outlive may terminate your access to and use of the Services, at our sole discretion, without prior notice or liability, for any reason, including but not limited to, a breach of these Terms.</p>
<p><strong>9. Governing Law and Jurisdiction</strong></p>
<p>These Terms shall be governed by the laws of the jurisdiction in which Outlive Technologies is incorporated, without regard to its conflict of law provisions. Any disputes arising from these Terms or your use of the Services will be subject to the exclusive jurisdiction of the courts located in the same jurisdiction as Outlive Technologies' LLC.</p>
<p><strong>10. Severability</strong></p>
<p>If any provision of these Terms is found to be invalid or unenforceable under applicable law, the remaining provisions will continue in full force and effect.</p>
<p><strong>11. Entire Agreement</strong></p>
<p>These Terms, together with any additional terms or policies referenced herein, constitute the entire agreement between you and Outlive Technologies concerning the Services and supersede all prior agreements or understandings regarding the subject matter.</p>
<p><strong>12. Contact Information</strong></p>
<p>If you have any questions or concerns about these Terms, the Services, or your dealings with Outlive Technologies, please contact us at info@vitalityassistant.com</p>
        <button className = 'modal-close-btn' onClick={onClose}>&times;</button>
      </div>
    </div>
  );
};

export default TermsOfUseModal;