import React from 'react';
import './mobileoutro.css';


const MobileOutro = () => {
    const handleButtonClick = () => {
        window.location.href = 'https://docs.google.com/forms/d/e/1FAIpQLSejEwpToNF6aoywrrcA3QNPxEG4RK3WFhCA9mg2boh3zBTOvQ/viewform?usp=sf_link';
      };

      const handleEmailFormClick = () => {
        window.location.href = 'https://docs.google.com/forms/d/e/1FAIpQLSdgE-WRNyHlhn4eJb_XaV1vWfIiA0zhKzYZYzyHUChSGeWBWg/viewform?usp=sf_link';
     };

  return (
    <div className='mcontainerforoutro'>
    
    <button onClick={handleEmailFormClick} className='mvitalitybuttonoutro'>email form</button>
    <button onClick={handleButtonClick} className='mvitalitybuttonoutro'>questionnaire</button>
    
    
    </div>
  );
};

export default MobileOutro;