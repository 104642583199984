import './mobile.css';

import Footervital from './components/footervital';
import MobileContent from './components/mobilecomponents/mobilecontent';

import MobileContent1 from './components/mobilecomponents/mobilecontent1';
import Mobileheader from './components/mobilecomponents/mobileheader';


import MobileOutro from './components/mobilecomponents/mobileoutro';
import SecondHeader from './components/mobilecomponents/secondheader';


function MobileApp() {
 
    
  return (
    <div className="MobileApp">
      <Mobileheader />
      <SecondHeader /> 
      
      <MobileContent />
      <MobileContent1 />
      <MobileOutro />
      <Footervital />
      <div className='Mobilecontent'>
      
      
      </div>
      
    </div>
  );
}

export default MobileApp;