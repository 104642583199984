import React from 'react';
import './mobilecontent1.css';


const MobileContent1 = () => {
  return (
    <div className='mcontainerforcontent1'>
    
    
   
    <div className='mtextforcontent1'>
    <div className='mparagraphcolumn'>
      <p className='mparagraphfullscreen'>At Vitality, we are embarking on an ambitious journey to transform the way we think about and engage with vitamins, supplements, and nutrition. Our vision is clear: to create a world where access to personalized nutrition and wellness is straightforward, affordable, and scientifically grounded. To bring this vision to life, we need your insight and participation.</p>
     </div>
     <div className='mparagraphcolumn'>
     
      <p className='mparagraphfullscreen'>Our solutions to the problems surrounding nutrition and wellness are two user-centric products - Vitality Market and Vitality Assistant. </p>
      </div>

      <div className='mparagraphcolumn'>
      
      <div>
      <p className='mparagraphheading'>Vitality Market:</p>
      <p className='mparagraph'>More than just a marketplace; Vitality Market will be an access point to a carefully selected range of vitamins, supplements, and nutritional products that cater to diverse needs and preferences, all at a fair price. The market will be augmented by all the educational resources and tools to build habits and optimize your health. </p>
      </div>
      </div>
      <div className='mparagraphcolumn'>
      <div>
      <p className='mparagraphheading'>Vitality Assistant:</p>
      <p className='mparagraph'>The Vitality Assistant is a digital companion that understands your needs. By considering your diet, lifestyle, and purchase in the market, it will offer personalized recommendations and eduction with the goal of enhance your overall well-being. Vitality Assistant is about creating a synergy between technology and personal health, a partnership where informed choices lead to a healthier life.</p>
      </div>
      </div> 
      
      
      <div className='mparagraphcolumn'>
      
      <div>
      <p className='mparagraphheading'>Our Mission:</p>
      <p className='mparagraph'>Change is at the core of what we aim to achieve. The landscape of health supplements and nutritional guidance is vast, often confusing, and deeply stigmatized. Vitality's mission is to demystify this space, offering clarity and evidence-based recommendations to improve overall wellness. We believe in a future where everyone has the tools and knowledge to make decisions and get products that positively impact their health and lifestyle.</p>
      </div>
      </div>
      <div className='mparagraphcolumn'>
        <div>
      <p className='mparagraphheading'>Join Us: </p>
      <p className='mparagraph'>This vision for Vitality is not just ours to build; it's a collaborative effort that thrives on the shared experiences and insights of our potential users - YOU. We are seeking to understand your needs, challenges, and aspirations when it comes to vitamins, supplements, nutrition, and wellness. Your input is invaluable, helping us tailor the Vitality Market and Assistant to meet your needs and exceed your expectations.</p>
      </div>
      </div>
      <div className='mparagraphcolumn'>
      <p className='mparagraph'>Together, we can redefine the approach to wellness, creating a platform that can shape a future where every individual can access the tools they need to thrive.</p>
      </div>
    
    </div>
    </div>
  );
};


export default MobileContent1;