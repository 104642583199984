import React from 'react';
import './header.css';


const Header = () => {


  return (
    <div className="header">
     
      <div className='companyname'>Changing the stigma of vitamins, supplements, and nutrition through accessibility and education</div>
      
    </div>
  );
};

export default Header;
