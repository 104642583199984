import React from 'react';
import './vitalityheader.css';
import vitality1 from '../images/vitality1.png';

const Vitalityheader = () => {
  

  return (
    <div className="vitalityheader">
    <img className='vitalityimgvitalityassistant' src={vitality1} alt='vitality assistant' />
      <div className='vitalitytitle'>
     
      </div>
    </div>
  );
};

export default Vitalityheader;